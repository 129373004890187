
  import { SmartBannerConfig } from './index'
  export const config: SmartBannerConfig = {
    festivalName: 'Panoramas#27',
    appTitle: 'Festival Panoramas',
    appStoreId: '0',
    appleStoreUrl: 'https://apps.apple.com/tn/app/pano-25/id1450798062',
    googleStoreUrl: 'https://play.google.com/store/apps/details?id=to.chapi.panoramas',
    appIconUrl: 'https://static.panoramas.chapi.to/app-icon.jpeg',
  }
